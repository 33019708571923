import React from 'react';

export default function Liam() {
  return (
    <svg
      width="22.63"
      height="24"
      viewBox="0 0 264 280"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M124,144.610951 L124,163 L128,163 C167.764502,163 200,195.235498 200,235 L200,244 L0,244 L0,235 C-4.86974701e-15,195.235498 32.235498,163 72,163 L76,163 L76,144.610951 C58.7626345,136.422372 46.3722246,119.687011 44.3051388,99.8812385 C38.4803105,99.0577866 34,94.0521096 34,88 L34,74 C34,68.0540074 38.3245733,63.1180731 44,62.1659169 L44,56 C44,25.072054 69.072054,5.68137151e-15 100,0 C130.927946,-5.68137151e-15 156,25.072054 156,56 L156,62.1659169 C161.675427,63.1180731 166,68.0540074 166,74 L166,88 C166,94.0521096 161.51969,99.0577866 155.694861,99.8812385 C153.627775,119.687011 141.237365,136.422372 124,144.610951 Z"
          id="path-1"
        ></path>
        <path
          d="M133.624032,0.268134161 C170.760022,3.13732446 200,34.1798426 200,72.0517235 L200,81 L0,81 L0,72.0517235 C-4.68022319e-15,33.8348009 29.7752018,2.57224935 67.3929298,0.196744437 C67.1342224,1.27354583 67,2.37671306 67,3.5 C67,15.3741221 81.9984609,25 100.5,25 C119.001539,25 134,15.3741221 134,3.5 C134,2.40152347 133.871641,1.32228769 133.624025,0.268133629 Z"
          id="path-3"
        ></path>
        <rect id="path-5" x="0" y="0" width="264" height="280"></rect>
        <path
          d="M93.3088634,9.00611798 C73.121091,-2.69348911 53.1289543,-0.778014556 38.0368941,3.02997068 C22.9448338,6.83795591 14.0168242,17.6513048 6.35667916,33.6480618 C2.59560476,41.5028569 0.366018216,50.7914798 0.0230569361,59.4813651 C-0.111557057,62.8932666 0.348430458,66.415154 1.26772904,69.7003546 C1.60494047,70.9061873 3.42324407,75.0869869 3.92246581,71.70885 C4.08853483,70.584253 3.48040428,69.0327505 3.42155293,67.8376154 C3.34443738,66.2693977 3.42730278,64.6807875 3.53418223,63.1149098 C3.73407386,60.1874141 4.25595676,57.3154127 5.18472567,54.524647 C6.51192494,50.5367405 8.20136439,46.212859 10.7874413,42.8480942 C17.1883705,34.5205939 28.268658,34.045883 37.0662575,29.4635848 C36.3028811,30.8686624 33.3596375,33.1432635 34.3793892,34.7268592 C35.083576,35.8207003 37.7494742,35.4890712 39.0222191,35.4539693 C42.3709958,35.3623702 45.7349928,34.7796791 49.0428442,34.306974 C54.2559233,33.5618115 59.1405848,32.0517626 64.0465546,30.2181078 C68.0629897,28.7164165 72.6500123,27.3257139 75.6686804,24.1404701 C80.5398129,29.1881187 86.8098486,33.9345599 93.0704139,37.1428706 C98.6883497,40.0222267 107.748751,41.461069 111.182761,47.3006826 C115.2479,54.2147477 113.37751,62.7073939 114.619138,70.2008069 C115.091302,73.05041 116.164155,72.986558 116.751315,70.4378281 C117.748406,66.1082634 118.219217,61.6102098 117.901961,57.1592929 C117.183568,47.1114668 113.496636,20.7057251 93.3088634,9.00611798 Z"
          id="path-7"
        ></path>
        <filter
          x="-3.1%"
          y="-9.8%"
          width="106.2%"
          height="119.5%"
          filterUnits="objectBoundingBox"
          id="filter-9"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowOffsetOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Liam">
          <g id="Body" transform="translate(32.000000, 36.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use fill="#D0C6AC" xlinkHref="#path-1"></use>
            <g id="🎨-Skin" mask="url(#mask-2)" fill="#FFDBB4">
              <g transform="translate(-32.000000, 0.000000)" id="Color">
                <rect x="0" y="0" width="264" height="244"></rect>
              </g>
            </g>
            <path
              d="M156,64 L156,102 C156,132.927946 130.927946,158 100,158 C69.072054,158 44,132.927946 44,102 L44,79 L44,94 C44,124.927946 69.072054,150 100,150 C130.927946,150 156,124.927946 156,94 L156,64 Z"
              id="Neck-Shadow"
              fillOpacity="0.100000001"
              fill="#000000"
              mask="url(#mask-2)"
            ></path>
          </g>
          <g id="👔-Clothes" transform="translate(32.000000, 199.000000)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <use id="Clothes" fill="#E6E6E6" xlinkHref="#path-3"></use>
            <g id="Color/Palette/Gray-02" mask="url(#mask-4)" fill="#929598">
              <g transform="translate(-32.000000, -29.000000)" id="🖍Color">
                <rect x="0" y="0" width="264" height="110"></rect>
              </g>
            </g>
            <g
              id="Clothing/Graphic/-Your-Text"
              mask="url(#mask-4)"
              fontFamily="Avenir-Heavy, Avenir"
              fontSize="28"
              fontWeight="600"
              letterSpacing="-0.4590163"
            >
              <g transform="translate(45.000000, 29.000000)" id="Hola!">
                <text>
                  <tspan x="20.0795408" y="31" fill="#FFFFFF">
                    React
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <g
            id="Face"
            transform="translate(76.000000, 82.000000)"
            fill="#000000"
          >
            <g
              id="👄-Mouth"
              transform="translate(42.000000, 66.000000)"
              fillOpacity="0.599999964"
              fillRule="nonzero"
            >
              <path
                d="M0,2 C0,7.37176301 6.15815438,11 14,11 C21.8418456,11 28,7.37176301 28,2 C28,0.8954305 27.050301,0 26,0 C24.7072748,0 24.1302316,0.905175505 24,2 C22.7575758,4.9378973 19.6832595,6.71631491 14,7 C8.31674049,6.71631491 5.24242424,4.9378973 4,2 C3.86976843,0.905175505 3.29272523,0 2,0 C0.949699016,0 0,0.8954305 0,2 Z"
                id="Mouth"
              ></path>
            </g>
            <path
              d="M44,48 C44,52.418278 49.372583,56 56,56 L56,56 C62.627417,56 68,52.418278 68,48"
              id="Nose"
              fillOpacity="0.16"
            ></path>
            <path
              d="M16.1601674,30.4473116 C18.006676,26.648508 22.1644225,24 26.9975803,24 C31.8136766,24 35.9591217,26.629842 37.8153518,30.4071242 C38.3667605,31.5291977 37.5821037,32.4474817 36.790607,31.7670228 C34.3395063,29.6597833 30.8587163,28.3437884 26.9975803,28.3437884 C23.2572061,28.3437884 19.8737584,29.5787519 17.4375392,31.5716412 C16.5467928,32.3002944 15.6201012,31.5583844 16.1601674,30.4473116 Z"
              id="Squint"
              fillOpacity="0.599999964"
            ></path>
            <path
              d="M74.1601674,30.4473116 C76.006676,26.648508 80.1644225,24 84.9975803,24 C89.8136766,24 93.9591217,26.629842 95.8153518,30.4071242 C96.3667605,31.5291977 95.5821037,32.4474817 94.790607,31.7670228 C92.3395063,29.6597833 88.8587163,28.3437884 84.9975803,28.3437884 C81.2572061,28.3437884 77.8737584,29.5787519 75.4375392,31.5716412 C74.5467928,32.3002944 73.6201012,31.5583844 74.1601674,30.4473116 Z"
              id="Squint"
              fillOpacity="0.599999964"
            ></path>
            <path
              d="M15.6302454,17.1585767 C19.545155,11.6498667 30.2779197,8.56083721 39.5230268,10.8311805 C40.5957248,11.0946055 41.6788665,10.4385601 41.9422916,9.36586212 C42.2057166,8.2931641 41.5496712,7.21002236 40.4769732,6.94659732 C29.7403633,4.30998211 17.3120996,7.88699832 12.3697546,14.8414233 C11.7298904,15.7417829 11.9410636,16.9903811 12.8414233,17.6302454 C13.7417829,18.2701096 14.9903811,18.0589364 15.6302454,17.1585767 Z"
              id="Eyebrow"
              fillOpacity="0.599999964"
              fillRule="nonzero"
            ></path>
            <path
              d="M73.6302454,17.1585767 C77.545155,11.6498667 88.2779197,8.56083721 97.5230268,10.8311805 C98.5957248,11.0946055 99.6788665,10.4385601 99.9422916,9.36586212 C100.205717,8.2931641 99.5496712,7.21002236 98.4769732,6.94659732 C87.7403633,4.30998211 75.3120996,7.88699832 70.3697546,14.8414233 C69.7298904,15.7417829 69.9410636,16.9903811 70.8414233,17.6302454 C71.7417829,18.2701096 72.9903811,18.0589364 73.6302454,17.1585767 Z"
              id="Eyebrow"
              fillOpacity="0.599999964"
              fillRule="nonzero"
              transform="translate(85.000154, 12.039198) scale(-1, 1) translate(-85.000154, -12.039198) "
            ></path>
          </g>
          <g id="Top">
            <mask id="mask-6" fill="white">
              <use xlinkHref="#path-5"></use>
            </mask>
            <g id="Mask"></g>
            <g mask="url(#mask-6)">
              <g transform="translate(73.000000, 26.000000)">
                <mask id="mask-8" fill="white">
                  <use xlinkHref="#path-7"></use>
                </mask>
                <use
                  id="Short-Hair"
                  stroke="none"
                  fill="#28354B"
                  fillRule="evenodd"
                  xlinkHref="#path-7"
                ></use>
                <g
                  id="↳-💈Hair-Color"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  mask="url(#mask-8)"
                >
                  <g
                    transform="translate(-73.000000, -26.000000)"
                    fill="#2C1B18"
                    id="Color"
                  >
                    <rect x="0" y="0" width="264" height="280"></rect>
                  </g>
                </g>
              </g>
            </g>
            <g id="↳-👓-Accessory" mask="url(#mask-6)">
              <g transform="translate(67.000000, 92.000000)" id="Wayfarers">
                <g filter="url(#filter-9)">
                  <path
                    d="M34,41 L31.2421498,41 C17.3147125,41 9,33.3359286 9,20.5 C9,10.127 10.8170058,0 32.5299306,0 L35.4700694,0 C57.1829942,0 59,10.127 59,20.5 C59,32.5686429 48.7212748,41 34,41 Z M32.3853606,6 C13,6 13,12.8410159 13,21.5015498 C13,28.5719428 16.116254,37 30.9709365,37 L34,37 C46.3649085,37 55,30.6270373 55,21.5015498 C55,12.8410159 55,6 35.6146394,6 L32.3853606,6 Z"
                    id="Left"
                    fill="#252C2F"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M96,41 L93.2421498,41 C79.3147125,41 71,33.3359286 71,20.5 C71,10.127 72.8170058,0 94.5299306,0 L97.4700694,0 C119.182994,0 121,10.127 121,20.5 C121,32.5686429 110.721275,41 96,41 Z M94.3853606,6 C75,6 75,12.8410159 75,21.5015498 C75,28.5719428 78.1194833,37 92.9709365,37 L96,37 C108.364909,37 117,30.6270373 117,21.5015498 C117,12.8410159 117,6 97.6146394,6 L94.3853606,6 Z"
                    id="Right"
                    fill="#252C2F"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M2.95454545,5.77156439 C3.64590909,5.09629136 11.2095455,0 32.5,0 C50.3513636,0 54.1302273,1.85267217 59.8502273,4.6518809 L60.2689233,4.85850899 C60.6666014,4.99901896 62.7002447,5.68982981 65.0790606,5.76579519 C67.2462948,5.67278567 69.1000195,5.08540191 69.641698,4.89719767 C76.1703915,1.7220864 82.5610971,0 97.5,0 C118.790455,0 126.354091,5.09629136 127.045455,5.77156439 C128.679318,5.77156439 130,7.06150904 130,8.65734659 L130,11.5431288 C130,13.1389663 128.679318,14.428911 127.045455,14.428911 C127.045455,14.428911 120.143997,14.428911 120.143997,17.3146932 C120.143997,20.2004754 118.181818,13.1389663 118.181818,11.5431288 L118.181818,8.73240251 C114.578575,7.35340151 108.128411,4.78617535 97.5,4.78617535 C85.6584651,4.78617535 79.7610984,6.88602813 74.7022935,8.97112368 L74.7588636,9.10752861 L74.7563667,11.0937608 L72.5391666,16.4436339 L69.8004908,15.3608351 C69.5558969,15.2641292 69.0281396,15.090392 68.2963505,14.9099044 C66.256272,14.4067419 64.1589087,14.253569 62.3040836,14.6343084 C61.6235903,14.7739931 60.9922286,14.9836085 60.4128127,15.266732 L57.7704824,16.5578701 L55.1266751,11.3962031 L55.2440909,9.10175705 L55.3248203,8.90683855 C50.9620526,6.87386374 46.9392639,4.78617535 32.5,4.78617535 C21.8721459,4.78617535 15.422131,7.3524397 11.8181818,8.7314671 L11.8181818,11.5431288 C11.8181818,13.1389663 8.86363636,20.2004754 8.86363636,17.3146932 C8.86363636,14.428911 2.95454545,14.428911 2.95454545,14.428911 C1.32363636,14.428911 0,13.1389663 0,11.5431288 L0,8.65734659 C0,7.06150904 1.32363636,5.77156439 2.95454545,5.77156439 Z"
                    id="Stuff"
                    fill="#252C2F"
                    fillRule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
